import { Tooltip } from '@mui/material'
import { Row } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { COMPANY_WIDE_TEXT } from '@traba/types'
import {
  assignedActiveLocationsForMember,
  getLocationNameOrTruncatedAddress,
  isBizMemberCompanyWide,
  sortLocationsByName,
} from '@traba/utils'
import React from 'react'
import { theme } from 'src/libs/theme'
import { UserWithRole } from 'src/types'
import SvgIcon from '../base/SvgIcon'

interface Props {
  member: UserWithRole
}

export const MemberLocations = ({ member }: Props) => {
  const isMemberCompanyWide = isBizMemberCompanyWide(member)
  const memberLocations = assignedActiveLocationsForMember(member)
  const locationNamesString = sortLocationsByName(memberLocations)
    .map(getLocationNameOrTruncatedAddress)
    .join(', ')

  return (
    <Tooltip title={isMemberCompanyWide ? undefined : locationNamesString}>
      <div>
        <Row gap={theme.space.xxs} alignCenter>
          <Row alignCenter>
            <SvgIcon
              name="location"
              size={16}
              color={theme.colors.MidnightBlue}
            />
          </Row>
          {isMemberCompanyWide
            ? COMPANY_WIDE_TEXT
            : `${memberLocations.length} location${makePlural(memberLocations.length)}`}
        </Row>
      </div>
    </Tooltip>
  )
}
