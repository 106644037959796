import { Tooltip } from '@mui/material'
import * as Sentry from '@sentry/react'
import {
  LinkText,
  Button,
  Row,
  SvgIcon,
  Text,
  ButtonVariant,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { ConnectionType, JobStatus, WorkerShift } from '@traba/types'
import { getShiftDateString } from '@traba/utils'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ShiftStatusBadge } from 'src/components/ShiftBadges/ShiftStatusBadge'
import { AddCostCentersButton } from 'src/components/WorkersOnShiftTable/components/AddCostCentersButton'
import { ViewCostCentersButton } from 'src/components/WorkersOnShiftTable/components/ViewCostCentersButton'
import { WorkerOnShiftTableCostCenters } from 'src/components/WorkersOnShiftTable/components/WorkerOnShiftTableCostCenters'
import { useWorkersWithSegments } from 'src/hooks/useCostCenters'
import { useReviews } from 'src/hooks/useReviews'
import { TimeSheetsWorkerCharge } from 'src/hooks/useTimesheet'
import { useWorkerShiftMutations } from 'src/hooks/workerShiftHooks'
import { TWO_MINUTES_IN_MS } from 'src/libs/constants'
import { TimesheetsGroupBy } from 'src/screens/TimeSheets/types'
import {
  getIsCompleteStatus,
  getIsReviewableStatus,
  getWorkerShiftStringId,
} from 'src/utils/workerShiftUtils'
import { ReviewWorkerForm } from '../../ReviewWorker/ReviewWorkerForm'
import { SlideOut } from '../../Slideout/Slideout'
import { Td, Tr } from '../../Table/Table'
import WorkerConnectionMenu, {
  WorkerConnectionActionButton,
} from '../../WorkerConnectionMenu/WorkerConnectionMenu'
import { WorkerLinkText } from '../../WorkerDetails/WorkerLinkText'
import { WorkerOnShiftTableBreaksField } from '../../WorkersOnShiftTable/components/WorkerOnShiftTableBreaksField'
import { WorkerOnShiftTableClockInField } from '../../WorkersOnShiftTable/components/WorkerOnShiftTableClockInField'
import { WorkerOnShiftTableClockOutField } from '../../WorkersOnShiftTable/components/WorkerOnShiftTableClockOutField'
import { WorkerOnShiftTableTotalWorked } from '../../WorkersOnShiftTable/components/WorkerOnShiftTableTotalWorked'
import { WorkerPhotoAndName } from '../../WorkersOnShiftTable/components/WorkerPhotoAndName'
import { useInReview } from '../../WorkersOnShiftTable/WorkersOnShiftTable.hooks'
import {
  BaseTimeSheetsTableWorkerShiftListItemProps,
  TimeSheetsTableLoggingSource,
} from '../types'
import { TimeSheetsWorkerEarning } from './TimeSheetsWorkerEarning'

interface TimeSheetsTableWorkerShiftListItemProps
  extends BaseTimeSheetsTableWorkerShiftListItemProps {
  workerCharge: TimeSheetsWorkerCharge | undefined
  isLoadingEstimatedCharge?: boolean
  numberOfColumns: number
  stickyApprovalsColumn?: boolean
  enableWorkerSegments?: boolean
}

export const TimeSheetsTableWorkerShiftListItem = (
  props: TimeSheetsTableWorkerShiftListItemProps,
) => {
  const {
    workerShift,
    workerCharge,
    worker,
    editMode,
    onEditWorkerShift,
    connections,
    refetchWorkerShifts,
    userCanViewWages,
    approveWorkerShifts,
    groupBy,
    hideHeaderRow,
    userCanManageTimesheets,
    isLoadingEstimatedCharge,
    numberOfColumns,
    stickyApprovalsColumn,
    loggingContext,
    enableWorkerSegments,
  } = props
  const isApproved = workerShift.isApproved
  const [isApproving, setIsApproving] = useState(false)
  const navigate = useNavigate()

  const { clockInWorkers, clockOutWorkers, editClockInWorkers } =
    useWorkerShiftMutations({
      data: [{ ...workerShift, worker }],
      onSuccess: refetchWorkerShifts,
    })

  const isWorkerShiftComplete = getIsCompleteStatus(workerShift.jobStatus)
  const isGroupedByShift = groupBy === TimesheetsGroupBy.SHIFT
  const shouldShowReview = getIsReviewableStatus(workerShift.jobStatus)
  const showShiftStatusBadge = workerShift.shiftInfo.endTime > new Date()
  const { data: workersSegmentsMap } = useWorkersWithSegments(
    workerShift.shiftId,
  )
  const workerSegments = workersSegmentsMap?.[workerShift.workerId]

  const [viewCostCenters, setViewCostCenters] = useState(false)
  const { inReview, toggleReview } = useInReview()
  const { reviews, refetchReviews } = useReviews({
    shiftId: workerShift.shiftId,
    workerId: workerShift.workerId,
  })
  const isWorkerReviewed = !!reviews?.length
  const handleCostCentersClick = () => {
    setViewCostCenters((viewCostCenters) => !viewCostCenters)
  }
  const handleApprove = async () => {
    setIsApproving(true)
    const workerShiftIds = [
      { workerId: workerShift.workerId, shiftId: workerShift.shiftId },
    ]
    try {
      await approveWorkerShifts(workerShiftIds)
      window.analytics.track(
        'Timesheets Table Worker Shift Approve Button Clicked',
        {
          workerShiftId: getWorkerShiftStringId(
            workerShift.workerId,
            workerShift.shiftId,
          ),
          ...loggingContext,
        },
      )
      refetchWorkerShifts()
    } catch (error) {
      Sentry.captureException(error, {
        tags: { action: 'approveWorkerShifts' },
        extra: { workerShiftIds },
      })
    }
    setIsApproving(false)
  }

  const onFavorite = useCallback(
    (workerShift: WorkerShift) => {
      connections?.favoriteWorker(workerShift.workerId).then((connection) => {
        toggleReview(
          [workerShift.workerId],
          'OPEN',
          ConnectionType.FAVORITE,
          connection.id,
        )
      })
    },
    [toggleReview, connections],
  )

  const showEditableTimeInput =
    editMode && !isApproved && workerShift.jobStatus === JobStatus.Complete

  const latestAdjustmentAt = workerShift.latestAdjustmentAt
  const isRecalculating = latestAdjustmentAt
    ? workerShift.charges.every(
        (charge) => charge.updatedAt < latestAdjustmentAt,
      )
    : workerShift.charges.length === 0 &&
      workerShift.shiftInfo.payRate !== 0 &&
      workerShift.jobStatus === JobStatus.Complete
  const isRecentlyRecalculated = workerShift.charges.some(
    (charge) =>
      new Date().getTime() - charge.updatedAt.getTime() < TWO_MINUTES_IN_MS,
  )

  return (
    <React.Fragment key={workerShift.workedShiftId}>
      <Tr style={{ height: 66 }}>
        {hideHeaderRow ? (
          <Td
            style={{
              alignItems: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            <WorkerLinkText
              onClick={() => navigate(`/worker/${worker.workerId}`)}
            >
              {worker.firstName} {worker?.lastName}
            </WorkerLinkText>
          </Td>
        ) : null}
        <Td
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {groupBy === TimesheetsGroupBy.SHIFT ? (
            <WorkerPhotoAndName
              worker={worker}
              onClickWorkerName={() => {
                window.analytics.track('Timesheets Worker Name Clicked', {
                  workerId: worker.workerId,
                  source:
                    TimeSheetsTableLoggingSource.TIMESHEETS_TABLE_WORKER_SHIFT_ROW,
                })
              }}
            />
          ) : (
            <Row>
              <LinkText
                center={true}
                onClick={() => {
                  navigate(`/calendar/${workerShift.shiftId}`)
                }}
              >
                {workerShift.shiftInfo.shiftRole}
              </LinkText>
              {showShiftStatusBadge && (
                <ShiftStatusBadge
                  shiftStartTime={workerShift.shiftInfo.startTime}
                  shiftEndTime={workerShift.shiftInfo.endTime}
                  shiftIsCanceled={false}
                  style={{
                    position: 'relative',
                    borderRadius: 10,
                    marginLeft: theme.space.xxs,
                  }}
                />
              )}
            </Row>
          )}
        </Td>
        {!isGroupedByShift && (
          <>
            <Td style={{ whiteSpace: 'nowrap' }}>
              <Text>
                {getShiftDateString(
                  workerShift.shiftInfo.startTime,
                  workerShift.shiftInfo.endTime,
                  workerShift.shiftInfo.timezone,
                  {
                    month: 'numeric',
                  },
                )}
              </Text>
            </Td>
            {!hideHeaderRow && (
              <Td style={{ whiteSpace: 'nowrap' }}>
                <Text>{workerShift.shiftInfo.shortLocation}</Text>
              </Td>
            )}
          </>
        )}
        {enableWorkerSegments && workerSegments && workerSegments.length > 0 ? (
          <ViewCostCentersButton
            handleCostCentersClick={handleCostCentersClick}
            viewCostCenters={viewCostCenters}
          />
        ) : enableWorkerSegments ? (
          <AddCostCentersButton
            handleAddCostCentersClick={() => {
              console.log('open add cost center modal')
            }}
          />
        ) : null}
        <Td style={{ whiteSpace: 'nowrap' }}>
          <WorkerOnShiftTableClockInField
            showEditableTimeInput={showEditableTimeInput}
            clockInWorkers={clockInWorkers}
            clockOutWorkers={clockOutWorkers}
            editClockInWorkers={editClockInWorkers}
            workerShift={{ ...workerShift, worker }}
            timezone={workerShift.shiftInfo.timezone}
            onEditWorkerShift={onEditWorkerShift}
            refetchWorkerShifts={refetchWorkerShifts}
            isFromTimesheetDetails={
              (isApproved && workerShift.jobStatus === JobStatus.Complete) ||
              editMode
            }
          />
        </Td>
        <Td style={{ whiteSpace: 'nowrap' }}>
          <WorkerOnShiftTableClockOutField
            showEditableTimeInput={showEditableTimeInput}
            clockInWorkers={clockInWorkers}
            clockOutWorkers={clockOutWorkers}
            editClockInWorkers={editClockInWorkers}
            workerShift={{ ...workerShift, worker }}
            timezone={workerShift.shiftInfo.timezone}
            onEditWorkerShift={onEditWorkerShift}
            refetchWorkerShifts={refetchWorkerShifts}
            isFromTimesheetDetails={
              (isApproved && workerShift.jobStatus === JobStatus.Complete) ||
              editMode
            }
          />
        </Td>
        <Td style={{ whiteSpace: 'nowrap' }}>
          <WorkerOnShiftTableBreaksField
            workerShift={{ ...workerShift, worker }}
            onEditWorkerShift={onEditWorkerShift}
            editMode={showEditableTimeInput}
            timezone={workerShift.shiftInfo.timezone}
            scheduledBreaks={workerShift.shiftInfo.scheduledBreaks}
            refetchWorkerShifts={refetchWorkerShifts}
            isFromTimesheetDetails={isApproved}
          />
        </Td>
        <Td style={{ whiteSpace: 'nowrap' }}>
          <WorkerOnShiftTableTotalWorked
            isWorkerShiftComplete={isWorkerShiftComplete}
            editMode={!!(editMode && !isApproved)}
            workerShift={workerShift}
          />
        </Td>
        {userCanViewWages && (
          <Td>
            <TimeSheetsWorkerEarning
              workerCharge={workerCharge}
              isLoading={!!isLoadingEstimatedCharge}
              showEstimatedCharge={workerShift.hasPendingEdits}
              showBizPendingAdjustmentTooltip={
                !editMode && !!workerShift.pendingAdjustment
              }
              isRecalculating={isRecalculating}
              isRecentlyRecalculated={isRecentlyRecalculated}
              onRefresh={refetchWorkerShifts}
              showFourHourMinBadge={workerShift.minimumPaidTimeSucceeded}
            />
          </Td>
        )}
        {!editMode && (
          <>
            {userCanManageTimesheets && (
              <Td
                style={
                  stickyApprovalsColumn
                    ? {
                        position: 'sticky',
                        right: 0,
                        zIndex: 1,
                        background: theme.colors.Grey10,
                        borderLeft: `1px solid ${theme.colors.Grey20}`,
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 66,
                        display: 'flex',
                      }
                    : {}
                }
              >
                {!isApproved ? (
                  <Button
                    rightIcon={<SvgIcon name="check" />}
                    variant={ButtonVariant.BRANDLINK}
                    iconPadding={`${theme.space.xxs}px`}
                    onClick={handleApprove}
                    loading={isApproving}
                    disabled={workerShift.jobStatus !== JobStatus.Complete}
                  >
                    {'Approve'}
                  </Button>
                ) : workerShift.shiftInfo.payRate === 0 ? (
                  <Tooltip title="This time is auto-approved by the Traba team because the shift has a $0 pay-rate.">
                    <Text
                      color={theme.colors.MidnightBlue}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {'Auto-Approved'}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text color={theme.colors.MidnightBlue}>Approved</Text>
                )}
              </Td>
            )}
            {isGroupedByShift && (
              <Td
                style={{
                  paddingRight: theme.space.xs,
                  width: 0,
                }}
              >
                <WorkerConnectionMenu
                  worker={worker}
                  isBlocked={
                    connections?.isBlocked(workerShift.workerId) || false
                  }
                  isFavorited={
                    connections?.isFavorite(workerShift.workerId) || false
                  }
                  isIneligible={
                    connections?.isIneligible(workerShift.workerId) || false
                  }
                  isScheduledBlock={
                    connections?.isScheduledBlock(workerShift.workerId) || false
                  }
                  hidden={!connections || !!editMode}
                  onFavorite={() => onFavorite(workerShift)}
                  workerShift={workerShift}
                  isReviewed={isWorkerReviewed}
                  onReview={() => {
                    toggleReview([workerShift.workerId], 'TOGGLE')
                  }}
                  buttonsToShow={
                    workerShift.jobStatus === JobStatus.ToDo
                      ? [
                          WorkerConnectionActionButton.AddToRoster,
                          WorkerConnectionActionButton.MessageWorker,
                        ]
                      : [
                          WorkerConnectionActionButton.AddToRoster,
                          WorkerConnectionActionButton.Block,
                          WorkerConnectionActionButton.Favorite,
                          WorkerConnectionActionButton.MessageWorker,
                          ...(shouldShowReview
                            ? [WorkerConnectionActionButton.ReviewWorker]
                            : []),
                        ]
                  }
                  alignEnd={true}
                />
              </Td>
            )}
          </>
        )}
      </Tr>
      {inReview.has(workerShift.workerId) ? (
        <tr>
          <td colSpan={numberOfColumns}>
            <SlideOut>
              <div
                style={{
                  padding: theme.space.sm,
                }}
              >
                <ReviewWorkerForm
                  variation={inReview.get(workerShift.workerId)?.variation}
                  workerName={worker.firstName}
                  workerId={workerShift.workerId}
                  shiftId={workerShift.shiftId}
                  requiredAttributes={
                    workerShift.shiftInfo?.requiredAttributes || []
                  }
                  onSaveReview={() => refetchReviews()}
                  connectionId={
                    inReview.get(workerShift.workerId)?.connectionId
                  }
                  onClose={() => {
                    toggleReview(
                      [workerShift.workerId],
                      'CLOSE',
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      inReview.get(workerShift.workerId)!.variation,
                    )
                  }}
                />
              </div>
            </SlideOut>
          </td>
        </tr>
      ) : null}
      {viewCostCenters && workerSegments && workerSegments.length > 0 && (
        <WorkerOnShiftTableCostCenters
          workerSegments={workerSegments}
          editMode={editMode}
          initialSkips={isGroupedByShift ? 0 : 2}
          actionsSkips={isGroupedByShift ? 2 : 1}
        />
      )}
    </React.Fragment>
  )
}
