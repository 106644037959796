import {
  CompanyInvitation,
  CompanyInvitationStatus,
  User,
  UserAccessLevel,
  UserWithRole,
} from '@traba/types'
import { isAfter, subWeeks } from 'date-fns'
import { isLocationActive } from './locationUtils'

export function isBizMemberCompanyWide(user: Partial<User>) {
  return user.userAccessLevel === UserAccessLevel.COMPANY_WIDE
}

export function isBizMemberLocationAssigned(user: Partial<User>) {
  return user.userAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED
}

export function assignedActiveLocationsForMember(user: Partial<User>) {
  return user.userAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED
    ? user.locations?.filter(isLocationActive) || []
    : []
}

export function hasRole(u: Partial<User>): u is UserWithRole {
  return !!u.role
}

export function isStatusAcceptable(status: CompanyInvitation['status']) {
  return [
    CompanyInvitationStatus.PENDING,
    CompanyInvitationStatus.SENT,
  ].includes(status)
}

export function isInvitationExpired(i: CompanyInvitation): boolean {
  return +i.expiresAt < Date.now()
}

export function isAfterTwoWeeksAgo(d: Date) {
  const twoWeeksAgo = subWeeks(new Date(), 2)
  return isAfter(d, twoWeeksAgo)
}

export function shouldDisplayInvitation(i: CompanyInvitation): boolean {
  return isStatusAcceptable(i.status) && isAfterTwoWeeksAgo(i.expiresAt)
}

export function matchesOutstandingOrAcceptedInvitation(
  m: Partial<User>,
  i: CompanyInvitation,
): boolean {
  if (i.email !== m.email) {
    return false
  }
  if (i.status === CompanyInvitationStatus.ACCEPTED) {
    return true
  }
  if (!isStatusAcceptable(i.status)) {
    return false
  }
  return !isInvitationExpired(i)
}

export function sortMemberByFullName(
  bizMemberA: Partial<User>,
  bizMemberB: Partial<User>,
) {
  const memberFullNameA = `${bizMemberA.firstName} ${bizMemberA.lastName}`
  const memberFullNameB = `${bizMemberB.firstName} ${bizMemberB.lastName}`
  return memberFullNameA.localeCompare(memberFullNameB)
}
