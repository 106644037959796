import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { CompanyInvitation, UserAccessLevel } from '@traba/types'
import { AxiosError } from 'axios'
import { useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import {
  CompanyInvitationAcceptanceData,
  CompanyInvitationAcceptanceDataWithSecret,
  CompanyInvitationCreationData,
  UserData,
  UserRole,
  UserRoleSetting,
} from 'src/types'

const getMemberRoleSettings = async () => {
  const response = await trabaApi.get(`/system/hot-settings`)
  const roles = response.data?.userRoles
  if (!roles) {
    throw new Error('User roles not found')
  }
  return roles
}

const getMembers = async () => {
  const response = await trabaApi.get(`/my-company/users`)
  return response.data
}

const getInvitations = async () => {
  const response = await trabaApi.get(`/my-company/invitations`)
  return response.data
}

interface CreateMemberParams extends Omit<UserData, 'userAccessLevel'> {
  userAccessLevel?: UserAccessLevel
}

const createMember = async (member: CreateMemberParams) => {
  const response = await trabaApi.post(`my-company/users/supervisor`, member)
  return response.data
}

interface ChangeMemberRole {
  uid: UserData['uid']
  role: UserRole | null
}

interface ArchiveMemberRequest {
  uid: UserData['uid']
  replacementUserId: string | undefined
}

const changeMemberRole = async (member: ChangeMemberRole) => {
  const response = await trabaApi.patch(`my-company/users/${member.uid}/role`, {
    role: member.role,
  })
  return response.data
}

const archiveMember = async (archiveMemberRequest: ArchiveMemberRequest) => {
  const response = await trabaApi.patch(
    `my-company/users/${archiveMemberRequest.uid}/archive`,
    { replacementUserId: archiveMemberRequest.replacementUserId },
  )
  return response.data
}

export const createInvitation = async (
  invitation: CompanyInvitationCreationData,
) => {
  const response = await trabaApi.post(`my-company/invitations`, invitation)
  return response.data
}

export const resendInvitation = async (
  invitationId: CompanyInvitation['invitationId'],
) => {
  const response = await trabaApi.patch(
    `my-company/invitations/${invitationId}/resend`,
  )
  return response.data
}

export const rescindInvitation = async (
  invitationId: CompanyInvitation['invitationId'],
) => {
  const response = await trabaApi.patch(
    `my-company/invitations/${invitationId}/rescind`,
  )
  return response.data
}

export const useMembers = () => {
  const queryClient = useQueryClient()
  const { handleError, showSuccess } = useAlert()
  const {
    isLoading,
    isError,
    data: members,
    error,
    isFetched,
    refetch: refetchMembers,
  } = useQuery<UserData[], Error>('/my-company/users', getMembers, {
    staleTime: FIVE_MINUTES_IN_MS,
  })
  const createMemberMutation = useMutation<
    UserData,
    AxiosError,
    CreateMemberParams,
    UserData
  >(createMember, {
    onSuccess: (response: UserData) => {
      queryClient.setQueryData(
        '/my-company/users',
        (currentMembers: UserData[] | undefined) => {
          if (currentMembers) {
            return [...currentMembers, response]
          }
          return [response]
        },
      )
    },
    onError: (error) => {
      handleError(
        error,
        'useMembers -> createMemberMutation',
        'There was an error creating your contact. Please try again or contact support if the issue persists.',
        'Error creating contact',
      )
    },
  })

  const changeMemberRoleMutation = useMutation<
    UserData,
    Error,
    ChangeMemberRole,
    UserData
  >(changeMemberRole, {
    onSuccess: ({
      role,
      uid,
      companyId,
    }: Pick<UserData, 'uid' | 'companyId' | 'role'>) => {
      queryClient.setQueryData(
        '/my-company/users',
        (currentMembers: UserData[] | undefined) => {
          if (currentMembers) {
            return currentMembers.map((m) =>
              m.uid === uid ? { ...m, role, uid, companyId } : m,
            )
          }
          return []
        },
      )
    },
    onError: (error: Error) => {
      const message =
        error?.message === 'user/no-admins'
          ? 'There must be at least one team member with the Admin role at all times.'
          : 'There was an error changing your team member‘s role. Please try again or contact support if the issue persists.'
      handleError(
        error,
        'useMembers -> changeMemberRoleMutation',
        message,
        'Error changing role',
      )
    },
  })

  const archiveMemberMutation = useMutation<
    UserData,
    AxiosError,
    ArchiveMemberRequest
  >(archiveMember, {
    onSuccess: (response: any) => {
      queryClient.setQueryData(
        '/my-company/users',
        (currentMembers: UserData[] | undefined) => {
          return currentMembers
            ? currentMembers.filter(
                (member: UserData) => member.uid !== response.uid,
              )
            : []
        },
      )
      const userType = response.role ? 'Member' : 'Contact'
      showSuccess(
        `${userType} will no longer be suggested when creating shifts.`,
        `${userType} archived!`,
      )
    },
  })

  const getMemberById = useCallback(
    (id: string) => {
      return members?.find((m) => m.uid === id)
    },
    [members],
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    members: members || [],
    refetchMembers,
    getMemberById,
    createMember: createMemberMutation.mutate,
    changeMemberRole: changeMemberRoleMutation.mutate,
    isChangeMemberRoleLoading: changeMemberRoleMutation.isLoading,
    archiveMember: archiveMemberMutation.mutate,
    isArchiveMemberLoading: archiveMemberMutation.isLoading,
  }
}

export function useInvitations() {
  const queryClient = useQueryClient()
  const { showSuccess, handleError } = useAlert()
  const {
    data: invitations,
    isLoading,
    error,
  } = useQuery<CompanyInvitation[]>('/my-company/invitations', getInvitations)

  const createInvitationMutation = useMutation<
    CompanyInvitation,
    AxiosError,
    CompanyInvitationCreationData
  >(createInvitation, {
    onSuccess: (response: CompanyInvitation) => {
      queryClient.setQueryData(
        '/my-company/invitations',
        (invitations: CompanyInvitation[] | undefined) => {
          if (invitations) {
            return [response, ...invitations]
          }
          return [response]
        },
      )
      showSuccess(
        `Your invitation was successfully sent to ${response.email}. They will receive an email shortly to join your company on Traba.`,
        'Invitation sent!',
      )
    },
    onError: (error: Error, req: CompanyInvitationCreationData) => {
      const message =
        error?.message === 'invitation/outstanding-or-accepted'
          ? `An invitation has already been sent to or accepted by ${req.email}.`
          : 'There was an error sending your invitation. Please try again or contact support if the issue persists.'
      handleError(
        error,
        'useInvitations -> createInvitation',
        message,
        'Error sending invitation',
      )
    },
  })

  const resendInvitationMutation = useMutation<
    CompanyInvitation,
    AxiosError,
    CompanyInvitation['invitationId']
  >(resendInvitation, {
    onSuccess: (response: CompanyInvitation) => {
      queryClient.setQueryData(
        '/my-company/invitations',
        (invitations: CompanyInvitation[] | undefined) => {
          if (invitations) {
            return invitations.map((i) =>
              i.invitationId === response.invitationId ? response : i,
            )
          }
          return [response]
        },
      )
    },
    onError: (error) => {
      handleError(
        error,
        'useInvitations -> resendInvitation',
        'There was an error resending your invitation. Please try again or contact support if the issue persists.',
        'Error resending invitation',
      )
    },
  })

  const rescindInvitationMutation = useMutation<
    CompanyInvitation,
    AxiosError,
    CompanyInvitation['invitationId']
  >(rescindInvitation, {
    onSuccess: (response: CompanyInvitation) => {
      queryClient.setQueryData(
        '/my-company/invitations',
        (invitations: CompanyInvitation[] | undefined) => {
          if (invitations) {
            return invitations.map((i) =>
              i.invitationId === response.invitationId ? response : i,
            )
          }
          return [response]
        },
      )
    },
    onError: (error) => {
      handleError(
        error,
        'useInvitations -> rescindInvitation',
        'There was an error revoking your invitation. Please try again or contact support if the issue persists.',
        'Error revoking invitation',
      )
    },
  })

  return {
    invitations,
    isLoading,
    error,
    createInvitation: createInvitationMutation.mutate,
    resendInvitation: resendInvitationMutation.mutate,
    rescindInvitation: rescindInvitationMutation.mutate,
  }
}

export function decodeInvitation(invitation: string) {
  const decoded = window.atob(invitation)

  const { invitationId, companyId, secret } = JSON.parse(decoded)

  if (
    typeof invitationId !== 'string' ||
    typeof companyId !== 'string' ||
    typeof secret !== 'string'
  ) {
    throw new Error('Invalid invitation param')
  }

  return {
    invitationId,
    companyId,
    secret,
  }
}

export function useInvitationValidation(query: URLSearchParams): {
  data?: CompanyInvitationAcceptanceDataWithSecret | undefined
  error?: Error | null
  invalidParams?: boolean
} {
  const invitation = query.get('invitation')
  if (!invitation) {
    return { invalidParams: true }
  }
  try {
    const { invitationId, companyId, secret } = decodeInvitation(invitation)
    const { data, error } = useQuery<CompanyInvitationAcceptanceData, Error>(
      ['invitation', invitationId, companyId, secret],
      () =>
        trabaApi
          .post(
            `/companies/${companyId}/invitations/${invitationId}/validate`,
            {
              secret,
            },
          )
          .then((r) => r.data),
      { retry: false },
    )
    return { data: data ? { ...data, secret } : undefined, error }
  } catch (error: any) {
    return {
      error: new Error('There is something wrong with your invitation'),
    }
  }
}

export function useMemberRoleSettings() {
  const {
    data: settings,
    isLoading,
    error,
  } = useQuery<UserRoleSetting[]>(
    '/settings/member-roles',
    getMemberRoleSettings,
  )
  return { settings, isLoading, error }
}
