import { INFINITE_SHIFT_REQ_CREATE_SHIFT_LEAD_TIME_WEEKS } from '@traba/consts'
import {
  CreateSchedule,
  RecurringSchedule,
  RecurringShiftsOutput,
  Shift,
} from '@traba/types'

import {
  add,
  addDays,
  addHours,
  addMinutes,
  addWeeks,
  differenceInCalendarWeeks,
  format,
  isAfter,
  isBefore,
  parseISO,
  startOfDay,
} from 'date-fns'
import { upperFirst } from 'lodash'
import { WeekdayStr, RRule, RRuleSet } from 'rrule'

interface Dater {
  toDate(): Date
}

// https://github.com/jakubroztocil/rrule/issues/336#issuecomment-548589766
// This creates a fake local UTC so that RRule can use the correct dates
export function setPartsToUTCDate(d: Date) {
  return new Date(
    Date.UTC(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds(),
    ),
  )
}

export function setPartsToUTCDateWithTimeZone(d: Date, timeZone: string) {
  const tzDate = new Date(d.toLocaleString('en-US', { timeZone }))
  return new Date(
    Date.UTC(
      tzDate.getFullYear(),
      tzDate.getMonth(),
      tzDate.getDate(),
      tzDate.getHours(),
      tzDate.getMinutes(),
      tzDate.getSeconds(),
    ),
  )
}

// This takes the returned fake local utc and set's it to the standard date object
export function setUTCPartsToDate(d: Date) {
  return new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds(),
  )
}

export function formatShiftTimeWithTime(time: Date, timezone: string) {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  }
  return time.toLocaleDateString('en-us', options)
}

export function formatShiftDateWithTimezone(date: Date, timezone: string) {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  return date.toLocaleDateString('en-US', options)
}

export function getShiftDateString(
  startTime: Date,
  endTime: Date,
  timezone: string,
  additionalOptions?: Intl.DateTimeFormatOptions,
) {
  try {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: timezone,
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      ...additionalOptions,
    }

    const startTimeString = startTime.toLocaleDateString('en-us', options)
    const endTimeString = endTime.toLocaleDateString('en-us', options)

    /*
      Check if day of the month is the same to determine if shift is overnight
      Given that the locale date strings can either resemble:
          - '<weekday>, <month> <day>, (optional) <year>' or
          - '<month> <day>, (optional) <year>'
      ...the index the <day> appears is either 2 (weekday present) or 1 (no
      weekday)
    */
    const dayIndex = options['weekday'] ? 2 : 1
    const isOvernightShift =
      startTimeString.split(' ')[dayIndex] !==
      endTimeString.split(' ')[dayIndex]

    let dateString = startTimeString
    if (isOvernightShift) {
      dateString += ` - ${endTimeString}`
    }
    return dateString
  } catch (err) {
    console.error(
      'dateUtils -> getShiftDateString() ERROR. Returning empty date string instead.',
      { startTime, endTime },
      err,
    )
    return ''
  }
}

export function getShiftTimeString(
  startTime: Date | string,
  endTime: Date | string,
  timezone: string,
) {
  try {
    if (typeof startTime === 'string') {
      startTime = new Date(startTime)
    }
    if (typeof endTime === 'string') {
      endTime = new Date(endTime)
    }

    const formattedStartTime = startTime.toLocaleString('en-US', {
      timeZone: timezone,
      hour: 'numeric',
      minute: '2-digit',
    })
    const formattedEndTime = endTime.toLocaleString('en-US', {
      timeZone: timezone,
      timeZoneName: 'short',
      hour: 'numeric',
      minute: '2-digit',
    })

    return `${formattedStartTime} - ${formattedEndTime}`
  } catch (err) {
    console.error(
      'dateUtils -> getShiftTimeString() ERROR. Returning empty time string instead.',
      { startTime, endTime },
      err,
    )
    return ''
  }
}

export function getReadableTimeInTimezone(
  date: Date,
  timezone?: string,
  hideTimeZoneName?: boolean,
): string {
  return date.toLocaleString('en-US', {
    timeZone: timezone,
    timeZoneName: !timezone || hideTimeZoneName ? undefined : 'short',
    hour: 'numeric',
    minute: '2-digit',
  })
}

export function getTime(date: Date): string {
  return date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  })
}

const isoDateFormat =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/

export function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value)
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object') {
    return body
  }

  for (const key of Object.keys(body)) {
    const value = body[key]
    if (isIsoDateString(value)) {
      body[key] = parseISO(value)
    } else if (typeof value === 'object') {
      handleDates(value)
    }
  }
}

/**
 * Given a certain time duration in minutes, returns a string
 * with the formatted duration in hours and minutes.
 * E.g.: m = 70 => '1h 10m'
 * @param m time duration expressed in minutes
 * @returns time duration express in hours/minutes format
 */
export function formatDuration(m: number) {
  const hours = Math.floor(m / 60)
  const minutes = Math.floor(m % 60)
  return `${hours > 0 ? `${hours}h ` : ``}${minutes <= 9 ? '0' : ''}${minutes}m`
}

export function reverseChronSort(a: Shift, b: Shift) {
  return isAfter(a.startTime, b.startTime) ? -1 : 1
}

export function anyToDate(
  timestampOrString?: Date | string | number | Dater | null,
): Date {
  if (!timestampOrString) {
    throw new Error(
      'dateUtils -> anyToDate() ERROR: timestanpOrString is undefined',
    )
  }
  if (
    typeof timestampOrString === 'string' ||
    typeof timestampOrString === 'number' ||
    timestampOrString instanceof Date
  ) {
    return new Date(timestampOrString)
  } else {
    return timestampOrString.toDate()
  }
}

export function sortByDate(a: Date, b: Date, order: 'ASC' | 'DESC' = 'ASC') {
  return order === 'ASC' ? +a - +b : +b - +a
}

export const WEEKDAY_NAMES: { [key in WeekdayStr]: string } = {
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thursday',
  FR: 'Friday',
  SA: 'Saturday',
  SU: 'Sunday',
}

// Sets the date of current to be after reference while maintaining the time.
// Used to keep endTime always in front of startTime, but within 24 hours.
export function getTimeAfterTimeWithin24Hours(time: Date, reference: Date) {
  const timeInReference = changeUnderlyingDate(time, reference)
  return timeInReference <= reference
    ? addDays(timeInReference, 1)
    : timeInReference
}

export function changeUnderlyingDate(time: Date, reference: Date) {
  const hours = time.getHours()
  const minutes = time.getMinutes()
  const referenceDayStart = startOfDay(reference)
  const result = addMinutes(addHours(referenceDayStart, hours), minutes)
  return result
}

export function setTimeFromDate(fromDate: Date, date: Date) {
  const hours = fromDate.getHours()
  const minutes = fromDate.getMinutes()
  const seconds = fromDate.getSeconds()
  const milliseconds = fromDate.getMilliseconds()

  return anyToDate(date.setHours(hours, minutes, seconds, milliseconds))
}

/** Get Shortened Timezone Acronym eg: EST, PST, EDT */
export const getTimeZoneAbbreviation = (date: Date, timezone: string) => {
  try {
    const match = date
      .toLocaleTimeString('en-us', {
        timeZoneName: 'short',
        timeZone: timezone,
      })
      .match(/([A-Z]{3})$/)

    if (!match) {
      return undefined
    }
    return match[0]
  } catch (err) {
    //Will happen if invalid timezone is passed
    return undefined
  }
}

/** Simple formatted date time with time zone: 'Sun, Dec 4, 2022, 2:04 PM PST' */
export const formatTimeDateString = (date: Date, timezone: string) => {
  return date.toLocaleDateString('en-us', {
    timeZone: timezone,
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    timeZoneName: 'short',
    hour: 'numeric',
    minute: '2-digit',
  })
}

/**
 * Simple formatted date time that defaults to 'Sun, Dec 4' but
 * can be easily overridden, e.g. to remove weekday and include year.
 */
export const formatDateString = (
  date: Date,
  options?: Intl.DateTimeFormatOptions,
) => {
  return date.toLocaleDateString('en-us', {
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    ...options,
  })
}

export function getLocalTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

// Function to combine the outputs of getRecurringShifts
export function combineRecurringShifts(
  recurringShiftsA: RecurringShiftsOutput,
  recurringShiftsB: RecurringShiftsOutput,
): RecurringShiftsOutput {
  const combinedShifts: RecurringShiftsOutput = {
    shiftCount: recurringShiftsA.shiftCount + recurringShiftsB.shiftCount,
    allShiftDates: [
      ...recurringShiftsA.allShiftDates,
      ...recurringShiftsB.allShiftDates,
    ].sort((a, b) => a.getTime() - b.getTime()),
    shiftCountWeekly:
      recurringShiftsA.shiftCountWeekly + recurringShiftsB.shiftCountWeekly,
  }

  if (recurringShiftsA.shiftDatesRRule && recurringShiftsB.shiftDatesRRule) {
    const rruleSet = new RRuleSet()
    rruleSet.rrule(recurringShiftsA.shiftDatesRRule)
    rruleSet.rrule(recurringShiftsB.shiftDatesRRule)
    combinedShifts.shiftDatesRRule = rruleSet
  } else {
    combinedShifts.shiftDatesRRule = recurringShiftsA.shiftDatesRRule
  }

  if (
    recurringShiftsA.shiftRequestStart &&
    recurringShiftsB.shiftRequestStart
  ) {
    combinedShifts.shiftRequestStart = dateMin(
      recurringShiftsA.shiftRequestStart,
      recurringShiftsB.shiftRequestStart,
    )
  } else {
    combinedShifts.shiftRequestStart = recurringShiftsA.shiftRequestStart
  }

  if (recurringShiftsA.shiftRequestEnd && recurringShiftsB.shiftRequestEnd) {
    combinedShifts.shiftRequestEnd = dateMax(
      recurringShiftsA.shiftRequestEnd,
      recurringShiftsB.shiftRequestEnd,
    )
  } else {
    combinedShifts.shiftRequestEnd = recurringShiftsA.shiftRequestEnd
  }

  if (
    recurringShiftsA.shiftRequestTextValue &&
    recurringShiftsB.shiftRequestTextValue
  ) {
    combinedShifts.shiftRequestTextValue = `${recurringShiftsA.shiftRequestTextValue}, ${recurringShiftsB.shiftRequestTextValue}`
  } else {
    combinedShifts.shiftRequestTextValue =
      recurringShiftsA.shiftRequestTextValue
  }

  return combinedShifts
}

export function getRecurringShifts(
  shiftRequestSchedule: CreateSchedule,
): RecurringShiftsOutput {
  if (!shiftRequestSchedule.recurringSchedule) {
    /** This should never happen.
     * This is safe because in the event of a recurringSchedule not being present, we want the
     * default return to be at least shiftCount: 1 and an array of allShiftDates with one element:
     * the schedule's startTime. This is necessary because we know that the rest of the schedule
     * exists, and since there is no recurringSchedule, it means that we are dealing with a single
     * shift. This is also how we handle defaults in the rest of this function's call sites at the
     * time this writing.
     * */
    console.error(
      '[getRecurringShifts] `shiftRequestSchedule.recurringSchedule` is undefined',
    )
    return {
      shiftCount: 1,
      allShiftDates: [shiftRequestSchedule.startTime],
      shiftCountWeekly: 1,
    }
  }

  const rruleInput = getRRule(
    shiftRequestSchedule.recurringSchedule,
    shiftRequestSchedule.startTime,
  )
  const shiftDatesRRule = new RRule(rruleInput)

  const shiftDates = shiftDatesRRule.all()
  const shiftRequestStart = shiftDates[0]
  const shiftRequestEnd = shiftDates.slice(-1)[0]
  const shiftCount = shiftDates.length
  const allShiftDates = shiftDates.map(setUTCPartsToDate)
  const shiftRequestTextValue = `${shiftDatesRRule.toText()} from ${getShiftTimeString(
    shiftRequestSchedule.startTime,
    shiftRequestSchedule.endTime,
    shiftRequestSchedule.timeZone,
  )}`
  const shiftCountWeekly =
    shiftRequestSchedule.recurringSchedule.repeatOn.length || 7

  return {
    shiftDatesRRule,
    shiftRequestStart,
    shiftRequestEnd,
    shiftCount,
    allShiftDates,
    shiftRequestTextValue,
    shiftCountWeekly,
  }
}

export function getRRule(
  recurringSchedule: RecurringSchedule,
  startTime: Date,
) {
  // Repeat Every:
  const {
    interval = 1, // 2
    freq = 'WEEKLY', // weeks (WEEKLY)
    repeatOn, // on Monday, Wednesday ([MO, WE])
    endDate, // Until May 22nd
  } = recurringSchedule

  const dtstart = setPartsToUTCDate(startTime)
  const until =
    endDate ??
    addWeeks(new Date(), INFINITE_SHIFT_REQ_CREATE_SHIFT_LEAD_TIME_WEEKS)

  switch (freq) {
    case 'WEEKLY':
      return {
        freq: RRule.WEEKLY,
        interval,
        byweekday: repeatOn.map((weekDay) => RRule[weekDay]),
        until,
        wkst: RRule.SU,
        dtstart,
      }
  }
}

export function dateMax(date1: Date, date2: Date): Date {
  return new Date(Math.max(date1.getTime(), date2.getTime()))
}

export function dateMin(date1: Date, date2: Date): Date {
  return new Date(Math.min(date1.getTime(), date2.getTime()))
}

export function formatShiftTime(
  startTime: Date,
  endTime: Date,
  timezone: string,
) {
  return `${getShiftDateString(
    startTime,
    endTime,
    timezone,
  )}, ${getShiftTimeString(startTime, endTime, timezone)}`
}

export const WEEKDAY_TO_NUM = {
  SU: 0,
  MO: 1,
  TU: 2,
  WE: 3,
  TH: 4,
  FR: 5,
  SA: 6,
}

export function sortWeekdays(weekdays: WeekdayStr[]): WeekdayStr[] {
  if (weekdays.length === 0) {
    return ['SU']
  }
  if (weekdays.length === 1) {
    return weekdays
  }
  return weekdays.sort((a, b) => WEEKDAY_TO_NUM[a] - WEEKDAY_TO_NUM[b])
}

/*
Biweekly schedule only:
This function determines if a new shift date is aligned with the schedule A so that we can send shift request with correct schedules start date
*/
export function isNewShiftDateAlignWithScheduleA(
  newShiftStartDate: Date,
  startTimeA: Date,
  startTimeB: Date,
): boolean {
  const referenceStartDate = startTimeA < startTimeB ? startTimeA : startTimeB
  const weekDifference = differenceInCalendarWeeks(
    newShiftStartDate,
    referenceStartDate,
  )
  return weekDifference % 2 === 0 ? true : false
}

/** Week B startTime = startTime of week A + 7 days + the delta between first day in Week A repeatOn
 * and first day in week B repeatOn
 * */
export function getNextStartAndEndTime(
  scheduleA: CreateSchedule,
  scheduleB: CreateSchedule,
): { startTime: Date; endTime: Date } {
  if (!scheduleA.recurringSchedule || !scheduleB.recurringSchedule) {
    return scheduleA
  }
  const originalStartTime = scheduleA.startTime
  const originalEndTime = scheduleA.endTime
  const repeatOnB = sortWeekdays(
    scheduleB.recurringSchedule?.repeatOn ?? ['SU'],
  )

  const deltaDays = WEEKDAY_TO_NUM[repeatOnB[0]] - originalStartTime.getDay()

  const startTime = add(originalStartTime, {
    weeks: 1,
    days: deltaDays,
  })
  const endTime = add(originalEndTime, {
    weeks: 1,
    days: deltaDays,
  })

  return { startTime, endTime }
}

export function getMonthFromDate(date: Date) {
  return date.toLocaleString('en-us', { month: 'short' })
}

export const REPEAT_ON_OPTIONS: { value: WeekdayStr; label: string }[] = [
  { value: 'SU', label: 'Sun' },
  { value: 'MO', label: 'Mon' },
  { value: 'TU', label: 'Tue' },
  { value: 'WE', label: 'Wed' },
  { value: 'TH', label: 'Thu' },
  { value: 'FR', label: 'Fri' },
  { value: 'SA', label: 'Sat' },
]

export const formatRepeatsOnForSchedules = (
  schedules: CreateSchedule[] | undefined,
) => {
  if (!schedules) {
    return ''
  }
  const isBiweekly = schedules.length > 1
  let result = `Repeats ${isBiweekly ? 'biweekly' : 'weekly'} on `
  schedules.forEach((schedule, index) => {
    const recurringSchedule = schedule.recurringSchedule
    if (recurringSchedule) {
      const repeatsOn = recurringSchedule.repeatOn.map((r) =>
        upperFirst(r.toLowerCase()),
      )
      const joinText = isBiweekly
        ? ` (week ${index + 1}) ${index === 0 ? '| ' : ''}`
        : ', '
      result =
        result +
        `${repeatsOn
          .sort(
            (a, b) =>
              (REPEAT_ON_OPTIONS.findIndex((opt) => opt.value === a) || 0) -
              (REPEAT_ON_OPTIONS.findIndex((opt) => opt.value === b) || 0),
          )
          .map((r) => upperFirst(r.toLowerCase()))
          .join(', ')}` +
        joinText
    }
  })

  return result
}

export const formatScheduleDateRange = (
  schedules: CreateSchedule[] | undefined,
) => {
  if (!schedules) {
    return ''
  }
  const timezone = schedules[0].timeZone
  const startTime = formatShiftDateWithTimezone(
    anyToDate(schedules[0].startTime),
    timezone,
  )

  const endDate = schedules[0].recurringSchedule?.endDate
  const endTime = endDate
    ? formatShiftDateWithTimezone(anyToDate(endDate), timezone)
    : 'No end'
  return `${startTime} - ${endTime}`
}

export const formatRangeForCalendar = (startDate: Date, endDate: Date) => {
  const startMonth = format(startDate, 'MMMM')
  const startDay = format(startDate, 'd')
  const endMonth = format(endDate, 'MMMM')
  const endDay = format(endDate, 'd')
  const year = format(startDate, 'yyyy')
  return `${startMonth} ${startDay}- ${startMonth !== endMonth ? endMonth : ''} ${endDay}, ${year}`
}

/**
 * Given a date, returns the start of the week that date is in.
 * Assumes that weeks start on Sunday.
 */
export function getWeekStart(date: Date) {
  const d = new Date(date)
  const day = d.getDay()
  const diff = d.getDate() - day // Adjust to start from Sunday
  return new Date(d.setDate(diff))
}

/**
 * Given two dates, combine them into a single date object with date from the first and time from the second.
 */
export function combineTwoDatesForDateAndTime(
  dateToGetDate: Date,
  dateToGetTime: Date,
) {
  const dateCopy = new Date(dateToGetDate)
  dateCopy.setHours(dateToGetTime.getHours())
  dateCopy.setMinutes(dateToGetTime.getMinutes())
  dateCopy.setSeconds(dateToGetTime.getSeconds())
  dateCopy.setMilliseconds(dateToGetTime.getMilliseconds())

  return dateCopy
}

export function getEarliestStartInSchedules(schedules: CreateSchedule[]) {
  return schedules.reduce((earliest, schedule) => {
    return isBefore(schedule.startTime, earliest)
      ? schedule.startTime
      : earliest
  }, schedules[0].startTime)
}

export function filterShiftsInFuture(shifts: Shift[]) {
  return shifts.filter((shift) => isAfter(shift.startTime, new Date()))
}
