export type CostCenterType = {
  id: string
  name: string
  status: CostCenterStatus
  locations: CostCenterLocation[]
}

export type CostCenterResponse = {
  members: CostCenterType[]
  page: number
  pageCount: number
  pageSize: number
}

export type CostCenterLocation = {
  id: string
  name: string
}

export type CreateCostCenter = {
  name: string
  locationIds?: string[]
}

export type UpdateCostCenter = {
  id: string
  name?: string
  status?: CostCenterStatus
  locationIds?: string[]
}

export type CostCenterFormData = {
  name?: string
  status?: CostCenterStatus
  locationIds?: string[]
}

export enum CostCenterStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type WorkerSegment = {
  costCenter?: {
    id: string
    name: string
  }
  startTime: Date
  endTime?: Date
  breakTime?: number
  workedTime?: number
  isBreak: boolean
}

export type WorkersWithSegmentsResponse = {
  members: WorkerWithSegments[]
  page: number
  pageCount: number
  pageSize: number
}

export type WorkerWithSegments = {
  worker: {
    id: string
    workerShiftId: string
    firstName: string
    lastName: string
    photoUrl?: string
  }
  status: string
  clockInTime?: Date
  clockOutTime?: Date
  totalBreakTime: number
  workedTime: number
  isPending: boolean
  segments: WorkerSegment[]
}

export type WorkersSegmentsMap = {
  [workerId: string]: WorkerSegment[]
}
