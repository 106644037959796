import { Col, DotMenu } from '@traba/react-components'
import { CompanyInvitation, USER_ROLE_NAMES } from '@traba/types'
import { isInvitationExpired, shouldDisplayInvitation } from '@traba/utils'
import React from 'react'
import { Table, Td, Tr } from 'src/components'
import { useInvitations } from 'src/hooks/useMembers'
import { useUserCanManageUsers } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { sortByDate } from 'src/shared/utils/dateUtils'
import { MEMBERS_COPY } from './constants'
import { StyledTdEditMenuContainer } from './MembersProfile.styles'
import { MembersSubheader } from './MembersSubheader'

// Sort invitations by expiration date. Filter out invitations that have been
// accepted, rescinded, expired for more than two weeks, or have a more recent
// invitation for the same email.
function getDisplayInvitations(
  invitations?: CompanyInvitation[],
): CompanyInvitation[] {
  if (!invitations) {
    return []
  }
  invitations.sort((a, b) => sortByDate(a.expiresAt, b.expiresAt, 'DESC'))
  const displayInvitations = []
  const emails = new Set<string>()
  for (const i of invitations) {
    if (!shouldDisplayInvitation(i)) {
      continue
    }
    if (emails.has(i.email)) {
      continue
    }
    displayInvitations.push(i)
    emails.add(i.email)
  }
  return displayInvitations
}

interface InvitationsTableMenuProps {
  invitation: CompanyInvitation
  onResend: (invitationId: CompanyInvitation['invitationId']) => void
  onRescind: (invitationId: CompanyInvitation['invitationId']) => void
}

function InvitationsTableMenu({
  invitation,
  onResend,
  onRescind,
}: InvitationsTableMenuProps) {
  const expired = +invitation.expiresAt < Date.now()
  const menuItems = [
    {
      title: 'Resend invitation',
      onClick: () => {
        onResend(invitation.invitationId)
        window.analytics.track(`User Clicked Resend Invitation`, {
          invitation,
          expired,
        })
      },
    },
    ...(!expired
      ? [
          {
            title: 'Revoke invitation',
            onClick: () => {
              onRescind(invitation.invitationId)
              window.analytics.track(`User Clicked Revoke Invitation`, {
                invitation,
              })
            },
          },
        ]
      : []),
  ]
  return (
    <StyledTdEditMenuContainer>
      <DotMenu
        type="invitations"
        dotMenuKey={invitation.invitationId}
        menuItems={menuItems}
      />
    </StyledTdEditMenuContainer>
  )
}

interface InvitationsTableProps
  extends Omit<InvitationsTableMenuProps, 'invitation'> {
  invitations: CompanyInvitation[]
  userCanEdit: boolean
}

function InvitationsTable({
  invitations,
  userCanEdit,
  onResend,
  onRescind,
}: InvitationsTableProps) {
  return (
    <Table
      headers={['Email', 'Role', 'Expires on', ...(userCanEdit ? [''] : [])]}
      style={{
        marginBottom: invitations.length === 0 ? undefined : theme.space.lg,
      }}
      itemType="invitations"
      showEmptyState
    >
      {invitations.map((i) => {
        const expired = isInvitationExpired(i)
        return (
          <Tr key={i.invitationId}>
            <Td>{i.email}</Td>
            <Td>{USER_ROLE_NAMES[i.role]}</Td>
            <Td style={{ color: expired ? theme.colors.Red60 : undefined }}>
              {expired ? 'Expired ' : ''}
              {i.expiresAt.toLocaleDateString('en-us')}
            </Td>
            {userCanEdit && (
              <InvitationsTableMenu
                invitation={i}
                onResend={onResend}
                onRescind={onRescind}
              />
            )}
          </Tr>
        )
      })}
    </Table>
  )
}

export function InvitationsSection() {
  const userCanEditActiveMembersAndInvitations = useUserCanManageUsers()

  const { invitations, resendInvitation, rescindInvitation } = useInvitations()
  const recentInvitations = getDisplayInvitations(invitations)

  function onResend(i: CompanyInvitation['invitationId']) {
    resendInvitation(i)
  }

  function onRescind(i: CompanyInvitation['invitationId']) {
    rescindInvitation(i)
  }

  return (
    <Col gap={theme.space.xs}>
      <MembersSubheader
        title={MEMBERS_COPY.INVITATIONS_TITLE}
        description={
          userCanEditActiveMembersAndInvitations
            ? MEMBERS_COPY.INVITATIONS_EDITOR_DESCRIPTION
            : MEMBERS_COPY.INVITATIONS_VIEWER_DESCRIPTION
        }
      />
      <div>
        <InvitationsTable
          invitations={recentInvitations}
          userCanEdit={userCanEditActiveMembersAndInvitations}
          onResend={onResend}
          onRescind={onRescind}
        />
      </div>
    </Col>
  )
}
