import { Tooltip } from '@mui/material'
import {
  Button,
  Td,
  Tr,
  Text,
  SvgIcon,
  ButtonVariant,
  Badge,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerSegment } from '@traba/types'
import { formatDuration } from '@traba/utils'

export const WorkerOnShiftTableCostCenters: React.FC<{
  workerSegments: WorkerSegment[]
  editMode: boolean | undefined
  initialSkips: number // This is the number of columns to skip at the beginning of the row
  actionsSkips: number // This is the number of columns to skip before the last column actions
}> = ({ workerSegments, editMode, initialSkips, actionsSkips }) => {
  return (
    <>
      {workerSegments.map((segment: WorkerSegment, index: number) => (
        <Tr
          key={`segment-${index}`}
          style={{ height: 66, backgroundColor: theme.colors.Grey10 }}
        >
          {Array.from({ length: initialSkips }, (_, i) => (
            <Td key={`initial-skip-${i}`} style={{ whiteSpace: 'nowrap' }}>
              {/* {Skip this column} */}
            </Td>
          ))}
          <Td style={{ whiteSpace: 'nowrap' }}>
            {segment.isBreak && <Badge variant="info" title="Break" />}
          </Td>
          <Td style={{ whiteSpace: 'text-wrap', maxWidth: theme.space.zero }}>
            <Text>{segment.costCenter?.name || 'None'}</Text>
          </Td>
          <Td style={{ whiteSpace: 'nowrap' }}>
            <Text>
              {segment.startTime.toLocaleTimeString([], {
                hour: 'numeric',
                minute: '2-digit',
              })}
            </Text>
          </Td>
          <Td style={{ whiteSpace: 'nowrap' }}>
            <Text>
              <Text>
                {segment.endTime
                  ? segment.endTime.toLocaleTimeString([], {
                      hour: 'numeric',
                      minute: '2-digit',
                    })
                  : ''}
              </Text>
            </Text>
          </Td>
          <Td style={{ whiteSpace: 'nowrap' }}>
            <Text>
              {formatDuration(segment.breakTime ? segment.breakTime : 0)}
            </Text>
          </Td>
          <Td style={{ whiteSpace: 'nowrap' }}>
            <Text>
              {formatDuration(segment.workedTime ? segment.workedTime : 0)}
            </Text>
          </Td>
          {Array.from({ length: actionsSkips }, (_, i) => (
            <Td key={`action-skip-${i}`} style={{ whiteSpace: 'nowrap' }}>
              {/* {Skip this column} */}
            </Td>
          ))}
          <Td
            style={{
              whiteSpace: 'nowrap',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: theme.space.xxs,
            }}
          >
            <Tooltip title={'Edit'}>
              <Button
                disabled={editMode}
                style={{ width: 48, height: 48, marginRight: theme.space.ms }}
                onClick={() => {
                  console.log('open edit modal')
                }}
                reverse
                variant={ButtonVariant.OUTLINED}
              >
                <SvgIcon name="edit" size={16} />
              </Button>
            </Tooltip>
          </Td>
        </Tr>
      ))}
    </>
  )
}
