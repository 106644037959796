import { Tooltip } from '@mui/material'
import { ShiftStatus, ShiftRequestParent } from '@traba/types'
import {
  formatShiftDateWithTimezone,
  getAddressString,
  getShiftTimeString,
} from '@traba/utils'
import React, { memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Icon,
  Row,
  SvgIcon,
  Text,
  Button,
  Col,
  ButtonVariant,
} from 'src/components/base'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { theme } from 'src/libs/theme'
import { ScheduleDailyViewWorkerTableContent } from 'src/screens/ScheduleDailyView/components/ScheduleDailyViewWorkerTableContent'
import { getDailyViewUrlSlug } from 'src/shared/utils/dateUtils'
import { truncateString } from 'src/utils/stringUtils'
import { ShiftStatusBadge } from '../ShiftBadges/ShiftStatusBadge'
import { WorkersOnShiftTable } from '../WorkersOnShiftTable'
import * as S from './ShiftTile.styles'
import { ShiftTileScheduleCadenceText } from './ShiftTileScheduleCadenceText'
import { isInProgressOrStartsInThreshold } from './ShiftTileTimebarUtils'
import ShiftTypeBadge, { ShiftType } from './ShiftTypeBadge'

interface Props {
  shiftCardTitle: string
  shiftRequestParent?: ShiftRequestParent
  shifts: ShiftAndAddress[]
  alwaysHideWorkers?: boolean
  defaultShowWorkerShifts: boolean
}

export const DesktopShiftsTileWithWorkersList: React.FC<Props> = memo(
  (props) => {
    const {
      shifts,
      shiftCardTitle,
      shiftRequestParent,
      alwaysHideWorkers,
      defaultShowWorkerShifts,
    } = props

    const navigate = useNavigate()

    const [isExpanded, setIsExpanded] = useState<boolean>(
      defaultShowWorkerShifts,
    )
    useEffect(() => {
      setIsExpanded(defaultShowWorkerShifts)
    }, [defaultShowWorkerShifts])

    const shift = shiftRequestParent
      ? shifts.find(
          (shift) =>
            shift.schedules?.[0].isRecurringSchedule &&
            shift.schedules?.[0].recurringSchedule,
        )
      : shifts[0]
    if (!shift) {
      return null
    }
    const schedules = shift.schedules
    const isRecurringSchedule =
      schedules?.length && schedules[0].isRecurringSchedule
    const endDateOfRecurringSchedule =
      shift.schedules?.[0].recurringSchedule?.endDate

    const {
      shiftId,
      shiftRole,
      endTime,
      confirmationCode,
      clockOutCode,
      timezone,
      address,
    } = shift
    const startTime = shift.businessStartTime ?? shift.startTime
    const originalStartTime = shift.originalStartTime
    const earliestStartTime = shifts.reduce((acc, shift) => {
      const tempStartTime = shift.businessStartTime ?? shift.startTime
      return tempStartTime < acc ? tempStartTime : acc
    }, startTime)
    const latestEndTime = shifts.reduce((acc, shift) => {
      const tempEndTime = shift.endTime
      return tempEndTime > acc ? tempEndTime : acc
    }, endTime)
    const addressString = getAddressString(address)
    const totalSlotsRequested = shifts.reduce(
      (acc, shift) => acc + shift.slotsRequested,
      0,
    )
    const totalSlotsFilled = shifts.reduce(
      (acc, shift) => acc + shift.slotsFilled,
      0,
    )

    return (
      <>
        <S.ExpandableShiftTileCard isExpanded={isExpanded}>
          <Row>
            {shift.status === ShiftStatus.CANCELED ? (
              <ShiftStatusBadge
                shiftStartTime={earliestStartTime}
                shiftEndTime={latestEndTime}
                shiftIsCanceled={true}
              />
            ) : isInProgressOrStartsInThreshold(
                earliestStartTime,
                latestEndTime,
              ) ? (
              <ShiftStatusBadge
                shiftStartTime={earliestStartTime}
                shiftEndTime={latestEndTime}
                shiftIsCanceled={false}
                isRecurringSchedulesEnabled
              />
            ) : null}
            <Row
              mt={theme.space.med}
              mb={theme.space.med}
              pl={theme.space.xs}
              pr={theme.space.xs}
              style={{ alignItems: 'center' }}
              fullWidth
            >
              <Row alignCenter>
                <S.ShiftTileCol
                  ml={theme.space.sm}
                  style={{
                    width: '130px',
                  }}
                >
                  <Row>
                    <Tooltip title={shiftRole}>
                      <Text
                        variant="h5"
                        onClick={() => {
                          if (shiftRequestParent) {
                            navigate(
                              `/schedule/${shiftRequestParent.shiftRequestParentId}`,
                            )
                          } else {
                            navigate(`/calendar/${shiftId}`)
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {truncateString(shiftCardTitle, 12, true)}
                      </Text>
                    </Tooltip>
                  </Row>
                  <ShiftTypeBadge
                    shiftType={
                      isRecurringSchedule
                        ? ShiftType.RECURRING
                        : ShiftType.ONE_TIME
                    }
                    iconName={isRecurringSchedule ? 'rotate_left' : undefined}
                    variant={isRecurringSchedule ? 'info' : 'brand'}
                  />
                </S.ShiftTileCol>
              </Row>
              <S.ShiftTileCol style={{ paddingRight: theme.space.med }}>
                <S.ShiftTileBodyText
                  variant="body2"
                  style={{ paddingBottom: theme.space.xxxs }}
                >
                  {getShiftTimeString(
                    earliestStartTime,
                    latestEndTime,
                    timezone,
                  )}
                </S.ShiftTileBodyText>
                <Tooltip title={addressString}>
                  <S.ShiftTileBodyText variant="body2">
                    {addressString}
                  </S.ShiftTileBodyText>
                </Tooltip>
              </S.ShiftTileCol>
              <S.ShiftTileCol className="sm">
                <S.ShiftTileBodyText
                  variant="body2"
                  style={{ paddingBottom: theme.space.xxxs }}
                >
                  {`Clock in: ${confirmationCode}`}
                </S.ShiftTileBodyText>
                <S.ShiftTileBodyText variant="body2">
                  {`Clock out: ${clockOutCode}`}
                </S.ShiftTileBodyText>
              </S.ShiftTileCol>
            </Row>

            {isRecurringSchedule && (
              <Col
                className="xs-2"
                style={{
                  justifyContent: 'center',
                  borderLeft: `2px solid ${theme.colors.Grey20}`,
                  minWidth: '20%',
                  alignItems: 'left',
                }}
              >
                <Row
                  pl={theme.space.xs}
                  mt={theme.space.med}
                  mb={theme.space.med}
                >
                  <S.ShiftTileCol>
                    <S.ShiftTileBodyText
                      variant="body2"
                      style={{
                        paddingBottom: theme.space.xxxs,
                        marginBottom: theme.space.xxxs,
                        minWidth: '100%',
                      }}
                    >
                      <ShiftTileScheduleCadenceText schedules={schedules} />
                    </S.ShiftTileBodyText>
                    {endDateOfRecurringSchedule && (
                      <S.ShiftTileBodyText variant="body2">
                        {`Until ${formatShiftDateWithTimezone(endDateOfRecurringSchedule, shift.timezone)}`}
                      </S.ShiftTileBodyText>
                    )}
                  </S.ShiftTileCol>
                </Row>
              </Col>
            )}

            <Col
              className="xs-2"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                borderLeft: `2px solid ${theme.colors.Grey20}`,
                minWidth: 200,
              }}
            >
              <Row mt={theme.space.sm} mb={theme.space.sm}>
                <S.ShiftTileCol
                  style={{ alignItems: 'start' }}
                  className="xs-3"
                >
                  <Button
                    variant={ButtonVariant.TEXT}
                    onClick={() => {
                      if (shiftRequestParent) {
                        navigate(
                          `/schedule/${shiftRequestParent.shiftRequestParentId}/${getDailyViewUrlSlug(new Date(originalStartTime))}`,
                        )
                      } else {
                        navigate(`/calendar/${shiftId}`)
                      }
                    }}
                    style={{
                      paddingBottom: 0,
                      color: theme.colors.brand,
                    }}
                  >
                    <SvgIcon
                      name={'link'}
                      color={theme.colors.brand}
                      size={theme.space.xs}
                      style={{ marginRight: theme.space.xxs }}
                    />
                    Shift details
                  </Button>
                  {isRecurringSchedule && (
                    <Button
                      variant={ButtonVariant.TEXT}
                      onClick={() => {
                        if (shiftRequestParent) {
                          navigate(
                            `/schedule/${shiftRequestParent.shiftRequestParentId}`,
                          )
                        }
                      }}
                      style={{
                        color: theme.colors.brand,
                        paddingBottom: 0,
                      }}
                    >
                      <SvgIcon
                        name={'link'}
                        color={theme.colors.brand}
                        size={theme.space.xs}
                        style={{ marginRight: theme.space.xxs }}
                      />
                      Full schedule
                    </Button>
                  )}
                  {!alwaysHideWorkers && (
                    <Row>
                      <Button
                        variant={ButtonVariant.TEXT}
                        onClick={() => setIsExpanded(!isExpanded)}
                        style={{
                          color: theme.colors.brand,
                          paddingBottom: 0,
                        }}
                      >
                        <Icon
                          name={isExpanded ? 'chevronUp' : 'chevronDown'}
                          style={{ marginRight: theme.space.xsmed }}
                        />
                        {isExpanded ? 'Hide Workers' : 'Show Workers'}{' '}
                        {'(' +
                          Math.min(
                            totalSlotsFilled,
                            totalSlotsRequested,
                          ).toString() +
                          '/' +
                          totalSlotsRequested.toString() +
                          ')'}
                      </Button>
                    </Row>
                  )}
                </S.ShiftTileCol>
              </Row>
            </Col>
          </Row>
        </S.ExpandableShiftTileCard>

        {isExpanded && (
          <S.ShiftTileWorkerListContainer>
            {isRecurringSchedule ? (
              <ScheduleDailyViewWorkerTableContent
                shifts={shifts}
                isFromDashboard
              />
            ) : (
              <WorkersOnShiftTable
                shift={shift}
                hideHeader={true}
                isUpcoming={true}
                maxRowsBeforeScroll={4}
              />
            )}
          </S.ShiftTileWorkerListContainer>
        )}
      </>
    )
  },
)
