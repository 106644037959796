import { useAlert } from '@traba/context'
import { ArchiveUserModalContent } from '@traba/react-components'
import React, { useCallback, useState } from 'react'

import { useMembers } from 'src/hooks/useMembers'
import { UserData } from 'src/types'

export type ArchiveUserModalProps = {
  member: UserData
  handleClose: () => void
}

export function ArchiveUserModal(props: ArchiveUserModalProps) {
  const { member, handleClose } = props
  const { members, archiveMember, isArchiveMemberLoading } = useMembers()
  const { showError } = useAlert()
  const [activeShiftsError, setActiveShiftsError] = useState(false)

  const handleArchiveClick = useCallback(
    async (replacementUserId?: string) => {
      await archiveMember(
        {
          uid: member.uid,
          replacementUserId: replacementUserId || undefined,
        },
        {
          onSuccess: handleClose,
          onError: (err) => {
            if (err.message === 'archive/active-shifts') {
              setActiveShiftsError(true)
              return
            } else {
              showError(
                'An error has occurred. Please try again or contact support if the issue persists.',
                `Unable to archive ${member.role ? 'member' : 'contact'}`,
              )
              handleClose()
            }
          },
        },
      )
      window.analytics.track(`User Archived User`, {
        user: member,
      })
    },
    [archiveMember, member, handleClose, showError],
  )

  return (
    <ArchiveUserModalContent
      member={member}
      replacementMembers={members}
      handleClose={handleClose}
      onArchiveMember={handleArchiveClick}
      loading={isArchiveMemberLoading}
      activeShiftsError={activeShiftsError}
    />
  )
}
